<template>
  <form class="mb-2">
    <div class="row" v-if="selectCampaignSetupMessage.length">
      <div class="col">
        <CampaignSetupsMessageBody :campaignSetup="selectCampaignSetup" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center px-3">
              <div class="col-auto px-2">
                <h4 class="card-title text-uppercase text-muted mb-4">This Campaign</h4>

                <div class="row align-items-center px-3">
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Recipients</h6>
                    <span class="h1 mb-0">{{ selectRecipientCount | prettiefiedNumber }}</span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">&nbsp;</h6>
                    <span class="fas fa-times text-muted mb-0"></span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Credits</h6>
                    <span class="h1 mb-0">{{ selectSmsCount | prettiefiedNumber }}</span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">&nbsp;</h6>
                    <span class="fas fa-chevron-right text-muted mb-0"></span>
                  </div>
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Total</h6>
                    <span class="h1 mb-0">{{ messageCount | prettiefiedNumber }}</span>
                  </div>
                </div>
              </div>
              <div class="col-auto px-3">
                <h4 class="card-title text-uppercase text-muted mb-4">&nbsp;</h4>

                <div class="row align-items-center px-3">
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">&nbsp;</h6>
                    <h1 class="fas fa-chevron-right text-muted mb-0"></h1>
                  </div>
                </div>
              </div>

              <div class="col-auto px-2">
                <h4 class="card-title text-uppercase text-muted mb-4">This Month</h4>

                <div class="row align-items-center px-3">
                  <div class="col-auto text-center px-2">
                    <h6 class="card-title text-uppercase text-muted mb-3">Credits</h6>
                    <span class="h1 mb-0">
                      {{ totalUsage | prettiefiedNumber }}
                      &nbsp;
                      <span class="h3 text-muted">
                        ({{ totalUsagePercent | prettiefiedNumber }}% of
                        {{ totalLimit | prettiefiedNumber }} limit)
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="alert alert-info"
          v-if="!selectActiveCompany.partner && isTextUsageLimitExceeded"
        >
          This campaign will put you {{ overageMessages | prettiefiedNumber }} credits over your
          limit of {{ textUsageLimit | prettiefiedNumber }} credits, and is estimated to cost
          {{ overageCost | currency }}
          in overages.
        </div>
      </div>
    </div>
    <hr class="mb-5" />
    <div class="d-flex align-items-center">
      <a
        v-if="showDraftBtn"
        @click="saveDraft"
        href="javascript:;"
        class="mr-4 btn btn-block btn-lg btn-outline-primary"
        :disabled="draftLoading"
        :class="{ 'is-loading': draftLoading }"
        >Save Draft</a
      >
      <a
        @click.once="preLaunch"
        href="javascript:;"
        class="mt-0 btn btn-block btn-lg btn-primary"
        :disabled="launchLoading"
        :class="{ 'is-loading': launchLoading }"
        >Launch Campaign</a
      >
    </div>
    <CampaignSetupsTCPAModal ref="tcpaModal" @save="launch" />
  </form>
</template>

<script>
import CampaignSetupsMessageBody from '@/components/Modules/CampaignSetups/Components/CampaignSetupsMessageBody'
import CampaignSetupsTCPAModal from '@/components/Modals/CampaignSetupsTCPAModal'
import moment from 'moment-timezone'
import { createNamespacedHelpers } from 'vuex'

const CampaignSetupsModule = createNamespacedHelpers('campaignSetups')
const CustomFilterModule = createNamespacedHelpers('customFilter')
const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'CreateCampaignSetupsSummary',
  components: {
    CampaignSetupsMessageBody,
    CampaignSetupsTCPAModal,
  },
  filters: {
    prettiefiedNumber(value) {
      return String(value)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
  data: () => ({
    launchLoading: false,
    draftLoading: false,
  }),
  mounted() {
    this.fetchTextUsage()
  },
  computed: {
    ...CustomFilterModule.mapGetters({ selectRecipientCount: 'selectCount' }),
    ...CampaignSetupsModule.mapGetters([
      'selectCampaignSetup',
      'selectSmsCount',
      'selectCampaignSetupMessage',
      'selectTextUsage',
    ]),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    messageCount() {
      return (this.selectSmsCount || 0) * this.selectRecipientCount || 0
    },
    totalUsage() {
      return this.selectTextUsage.usedCredits + this.selectTextUsage.scheduledCredits
    },
    totalLimit() {
      return this.selectTextUsage.creditLimit + this.selectTextUsage.creditBalance
    },
    totalUsagePercent() {
      return parseInt(((this.totalUsage / this.totalLimit) * 100).toFixed(), 10) || 0
    },
    overageCost() {
      const textOverageCost =
        this.selectActiveCompany._id === 'ALL_COMPANIES'
          ? this.selectOrganization.textOverageCost
          : this.selectActiveCompany.textOverageCost
      return this.overageMessages * textOverageCost
    },
    overageMessages() {
      return this.totalUsage - this.totalLimit
    },
    isTextUsageLimitExceeded() {
      return this.overageMessages > 0
    },
    showDraftBtn() {
      return !this.selectCampaignSetup._id || this.selectCampaignSetup.status === 'draft'
    },
    textUsageLimit() {
      return this.selectActiveCompany._id === 'ALL_COMPANIES'
        ? this.selectOrganization.textUsageLimit
        : this.selectActiveCompany.textUsageLimit
    },
  },
  methods: {
    ...CampaignSetupsModule.mapActions([
      'updateCampaignSetup',
      'createCampaignSetup',
      'resetCampaignSetup',
      'setEstimatedCreditUsage',
      'setCampaignSetupStatus',
      'setCampaignSetup',
      'fetchTextUsage',
      'setIsSavingOrUpdating',
    ]),

    async saveDraft() {
      this.draftLoading = true
      try {
        await this.saveOrUpdateCampaign('draft')
      } finally {
        this.draftLoading = false
      }
    },

    async preLaunch() {
      this.launchLoading = true
      let blastFinishesAfterBlackout = true
      if (this.selectCampaignSetup.type === 'blast') {
        blastFinishesAfterBlackout = await this.checkSendDurationForBlackout()
        this.setCampaignSetupStatus('initialized')
      }
      if (this.selectCampaignSetup.type === 'auto') {
        this.setCampaignSetupStatus('active')
      }
      if (this.selectCampaignSetup.type === 'auto' || !blastFinishesAfterBlackout) {
        await this.launch()
      }
    },

    async launch() {
      await this.saveOrUpdateCampaign()
      this.launchLoading = false
    },

    async checkSendDurationForBlackout() {
      // calculate how long campaign will take to send and check if it will go into blackout hours
      const smsThroughput = 20
      const mmsThroughput = 3
      const blackoutTime = '20:59'
      let secondsToRun = 0
      if (!this.selectCampaignSetup.isMMS) {
        secondsToRun = Math.ceil(this.messageCount / smsThroughput)
      } else {
        secondsToRun = Math.ceil(this.selectRecipientCount / mmsThroughput)
      }

      // if it will carry into blackout then show modal for selection
      const baseDate = this.selectCampaignSetup.startDate
        ? moment(this.selectCampaignSetup.startDate)
        : moment()
      const sendCompletionDateTime = moment(baseDate).add(secondsToRun, 's')
      const comparisonAfterHours = moment(baseDate).set({ hour: 20, minute: 59, second: 0 })
      const completionAfterBlackout = sendCompletionDateTime.isAfter(comparisonAfterHours)
      if (completionAfterBlackout) {
        this.$root.$emit('bv::show::modal', 'tcpaModal')
      }

      return completionAfterBlackout
    },

    async saveOrUpdateCampaign(campaignStatus) {
      let response = ''
      let title = 'Launched!'
      let msgText = `Your campaign is ready to go.`
      try {
        this.setIsSavingOrUpdating(true)
        this.setCampaignSetupStatus(campaignStatus)
        if (this.selectCampaignSetup.type === 'blast') {
          this.setEstimatedCreditUsage(this.messageCount)
        }

        // If we are updating an existing auto campaignSetup
        if (this.selectCampaignSetup._id) {
          response = await this.updateCampaignSetup({
            campaignSetupId: this.selectCampaignSetup._id,
            campaignSetup: this.selectCampaignSetup,
          })
        } else {
          // Otherwise create a new campaignSetup
          response = await this.createCampaignSetup({ campaignSetup: this.selectCampaignSetup })
        }

        this.resetCampaignSetup()
        this.setIsSavingOrUpdating(false)

        if (campaignStatus === 'draft') {
          title = 'Draft Saved!'
          msgText = 'Draft campaign is saved.'
        }

        this.$notify({
          title: title,
          text: msgText,
        })

        this.$router.push({ name: 'campaigns' })
      } catch (err) {
        console.log(err)
        this.errMessage = err.body?.message
        this.$notify({
          title: 'Failed to create campaign.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
  },
  watch: {
    messageCount: function (value) {
      this.setEstimatedCreditUsage(value)
    },
  },
}
</script>
